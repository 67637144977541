import ProductionService from '../../../service/ProductionService'
import * as mutationTypes from './types'

export const getAll = async ({ commit }) => {
  const { data } = await ProductionService.getAll()
  commit('SET_LIST', data)

  return data.items
}

export const getList = async ({ commit }, query) => {
  const { data } = await ProductionService.paginatedSearchInternalCost(query)
  commit('SET_LIST', data)

  return data
}

export const exportExcel = async ({ commit, rootState }, query) => {
  try {
    commit(mutationTypes.exportExcel.requesting)
    const res = await ProductionService.exportExcel(query)
    commit(mutationTypes.exportExcel.success, { rootState })

    return res
  } catch (e) {
    commit(mutationTypes.exportExcel.failure, { payload: e, rootState })
  }
}

export const getTotal = async ({ commit }, query) => {
  delete query.filters.projectReference
  delete query.filters['project.reference']
  delete query.filters['project.name']
  delete query.filters['project.localClient.name']
  delete query.filters['service.local_product.name']
  delete query.filters['service.amount']
  delete query.filters['companyPercent']
  delete query.filters['companyRoles']
  delete query.filters['billed.amount']

  const { data } = await ProductionService.totalInternalCost(query)
  commit('SET_TOTAL', data)

  return data
}
