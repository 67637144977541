import { between, decimal, helpers, required } from '@vuelidate/validators'
import { booleanValidator } from '@/common/utils/validator.utils'

const actionHistory = {
  CREATE: 'create',
  UPDATE: 'update',
  ADD_CONTACT: 'add_contact',
  UPDATE_CONTACT: 'update_contact',
  ADD_SERVICES: 'add_services',
  UPDATE_SERVICES: 'update_services',
  CLONED: 'cloned',
  SUBMITTED_TO_BILLER: 'submitted_to_biller',
}

export const historyView = {
  [actionHistory.CREATE]: {
    color: 'rgba(0, 0, 0, 0.40)',
    text: 'Created',
  },
  [actionHistory.UPDATE]: {
    color: '#9c27b0',
    text: 'Updated',
  },
  [actionHistory.ADD_CONTACT]: {
    color: '#23547b',
    text: 'Contact added',
  },
  [actionHistory.UPDATE_CONTACT]: {
    color: '#fa8e42',
    text: 'Contact updated',
  },
  [actionHistory.ADD_SERVICES]: {
    color: '#14b8a6',
    text: 'Services added',
  },
  [actionHistory.UPDATE_SERVICES]: {
    color: '#084a42',
    text: 'Services updated',
  },
  [actionHistory.CLONED]: {
    color: '#CCCCCC',
    text: 'Cloned',
  },
  [actionHistory.SUBMITTED_TO_BILLER]: {
    color: '#a4fa42',
    text: 'Submitted to biller',
  },
}

export const billingTypes = {
  PRE_BILLED: 'pre_billed',
  ON_PROGRESS: 'on_progress',
}

export const billingFrequencyPeriods = {
  MONTHLY: 'monthly',
  QUARTERLY: 'quarterly',
  SEMESTERLY: 'semesterly',
  ANNUALLY: 'annually',
}

export const billingFrequencyTimings = {
  BEGIN: 'begin',
  END: 'end',
}

export const feeScopes = {
  CUSTOM: 'custom',
  REVENUE: 'revenue',
  ROI: 'roi',
  MEDIA_SPENT: 'media_spent',
  PERCENTAGE_EXECUTION: 'percentage_execution',
  TIME_TRACKING_DAY: 'time_tracking_day',
  TIME_TRACKING_HOUR: 'time_tracking_hour',
  FIXED: 'fixed',
  OTHER_LEAD: 'other_lead',
  NUMBER_OF_CAMPAIGNS: 'number_of_campaigns',
}

export const feePricingTypes = {
  TIERED: 'tiered',
  VOLUME: 'volume',
  AMOUNT_PER_TIERED: 'amount_per_tiered',
}

export const tieredFeeScopes = [
  feeScopes.REVENUE,
  feeScopes.ROI,
  feeScopes.MEDIA_SPENT,
  feeScopes.OTHER_LEAD,
  feeScopes.NUMBER_OF_CAMPAIGNS,
]

export const tierModes = {
  FIXED: 'fixed',
  PERCENTAGE: 'percentage',
  AMOUNT: 'amount',
}

export const feesTemplateTypes = {
  FIXED: 'fixed',
  PERCENTAGE: 'percentage',
  PERCENTAGE_FIXED: 'percentage_fixed',
  CUSTOM: 'custom',
  TIME_TRACKING_DAY: 'time_tracking_day',
  TIME_TRACKING_HOUR: 'time_tracking_hour',
  AMOUNT: 'amount',
  AMOUNT_FIXED: 'amount_fixed',
}

export const serviceModel = {
  unit_price: 0,
  quantity: 0,
  discount: null,
  amount: null,
  local_product: null,
  assignee: null,
  description: null,
  billing_type: null,
  billing_frequency_period: null,
  billing_frequency_timing: null,
  platforms: [],
  platformAutomation: null,
  addPlatformDescription: null,
  feesTemplate: null,
  feesList: null,
  purchase_order: null,
  start_date: null,
  end_date: null,
}

export const serviceRules = {
  unit_price: {
    decimal,
    required,
  },
  quantity: {
    decimal,
    required,
  },
  discount: {
    decimal,
    between: between(0, 100),
  },
  assignee: {},
  description: {},
  billing_type: {},
  billing_frequency_period: {},
  billing_frequency_timing: {},
  platforms: {},
  platformAutomation: {
    booleanValidator,
    canBeTrueIfHasPlatforms: helpers.withMessage(
      'Platform Automation need at least one platform with an account ID.',
      (value, siblings) => {
        if (!helpers.req(value)) return true

        const platforms =
          siblings?.platforms?.filter(
            (servicePlatform) =>
              servicePlatform._deletion !== true &&
              servicePlatform.is_deleted !== true,
          ) ?? []

        if (!platforms.length) return true

        return !(
          value === true &&
          !platforms.some(
            (servicePlatform) =>
              servicePlatform.platform && servicePlatform.accountId,
          )
        )
      },
    ),
  },
  addPlatformDescription: {
    booleanValidator,
    canBeTrueIfHasPlatforms: helpers.withMessage(
      'Platform Automation need at least one platform with an account ID.',
      (value, siblings) => {
        if (!helpers.req(value)) return true

        const platforms =
          siblings?.platforms?.filter(
            (servicePlatform) =>
              servicePlatform._deletion !== true &&
              servicePlatform.is_deleted !== true,
          ) ?? []

        if (!platforms.length) return true

        return !(
          value === true &&
          !platforms.some(
            (servicePlatform) =>
              servicePlatform.platform && servicePlatform.accountId,
          )
        )
      },
    ),
  },
  feesTemplate: {},
  feesList: {},
  purchase_order: {},
  tags: {},
  start_date: {},
  end_date: {},
}

export const projectType = {
  SIMPLE: 'Simple',
  INTERNAL: 'Internal',
}

export const projectTypes = {
  SIMPLE_PROJECT: 'simpleProject',
  INTERNAL_PROJECT: 'internalProject',
  PROGRAM_MANAGEMENT: 'programManagement',
}

export const baseProjectStates = {
  QUOTE: 'Quote',
  LOST: 'Lost',
  CANCELED: 'Canceled',
  SIGNED: 'Signed',
  ARCHIVED: 'Archived',
}

export const projectSizes = [
  {
    value: 'small',
    name: 'Small (< 10 000€)',
    color: 'success',
  },
  {
    value: 'medium',
    name: 'Medium (10 000€ to 50 000€)',
    color: 'info',
  },
  {
    value: 'large',
    name: 'Large (50 000€ to 100 000€)',
    color: 'warn',
  },
  {
    value: 'extra_large',
    name: 'Extra Large (>100 000€)',
    color: 'danger',
  },
]

export const transfertPricingRules = [
  {
    value: '1.0',
    name: 'Pricing Rule 1.0',
  },
  {
    value: '2.0',
    name: 'Pricing Rule 2.0',
    startDate: '2025-01-01',
  },
]

export const levelsOfInvolvement = [
  {
    value: 'Level 1',
    name: 'Level 1 (Basic | Client Referral)',
    color: 'success',
  },
  {
    value: 'Level 2',
    name: 'Level 2 (Intermediate | Deal Provider)',
    color: 'info',
  },
  { value: 'Level 3', name: 'Level 3 (High | Sales Agent)', color: 'warn' },
]

export const baseProjectStatesValues = Object.values(baseProjectStates)

export const baseProjectStateOptions = Object.values(baseProjectStates).map(
  (state) => {
    return {
      label: state,
      value: state,
    }
  },
)
