import { exportExcel } from './types'

export default {
  SET_LIST(state, { items, count }) {
    state.list = [...items]
    state.totalRecords = count
  },
  ADD_TO_LIST(state, item) {
    state.list = [item, ...state.list]
    state.totalRecords = state.totalRecords + 1
  },
  [exportExcel.requesting](state) {
    state.isExporting = true
  },
  [exportExcel.success](state, { rootState }) {
    rootState.message = { text: 'Exported successfully', type: 'error' }
    state.isExporting = false
  },
  [exportExcel.failure](state, { rootState, payload }) {
    rootState.message = { text: payload.message, type: 'error' }
    state.isExporting = false
  },

  SET_TOTAL(state, { items }) {
    state.total = [...items]
  },
}
