import useStorage from '@/composables/useStorage'
import UserService from '@/service/UserService'
import store from '@/store'

const runGuards = (guards, to, from, next) => {
  const remainingGuards = guards.slice(0)
  const nextGuard = remainingGuards.shift()

  if (nextGuard === undefined) {
    next()

    return
  }

  nextGuard(to, from, (nextArg) => {
    if (nextArg === undefined) {
      runGuards(remainingGuards, to, from, next)

      return
    }

    next(nextArg)
  })
}

export function guardChain(guards) {
  if (!Array.isArray(guards)) {
    throw new Error('You must specify an array of guards')
  }

  return (to, from, next) => {
    return runGuards(guards, to, from, next)
  }
}

export function authGuard(to, from, next) {
  const { getStorageItem } = useStorage()
  const authData = getStorageItem('AUTH_DATA')

  if (authData) {
    if (!authData?.user?.roles?.length) {
      return next('/account/pending-approval')
    }
    next()
  } else {
    next('/login')
  }
}

export function companyGuard(to, from, next) {
  const { getStorageItem } = useStorage()
  const currentCompany = getStorageItem('CURRENT_COMPANY')?._id

  if (currentCompany) {
    next()
  } else {
    next({
      name: 'dashboard',
      params: { idCompany: currentCompany },
    })
  }
}

export function noAuthGuard(to, from, next) {
  if (localStorage.getItem('AUTH_DATA')) {
    const { getStorageItem } = useStorage()
    const currentCompany = getStorageItem('CURRENT_COMPANY')?._id
    next({
      name: 'dashboard',
      params: { idCompany: currentCompany },
    })
  } else {
    next()
  }
}

export async function checkIdCompany(to, from, next) {
  const { getStorageItem, setStorageItem } = useStorage()
  const storageIdCompany = getStorageItem('CURRENT_COMPANY')._id
  const authData = getStorageItem('AUTH_DATA')
  const urlIdCompany = to.params.idCompany

  if (storageIdCompany === undefined || storageIdCompany === null) {
    next({ name: 'login', params: { message: 'Missing selected company' } })
  } else if (storageIdCompany === urlIdCompany) {
    next()
  } else {
    const idCompanies = authData.user.companies.map((comp) => comp._id)

    if (idCompanies.includes(storageIdCompany)) {
      await UserService.changeCompany(storageIdCompany)
      const findCompanie = authData.user.companies.find(
        (obj) => obj._id === storageIdCompany,
      )
      const filteredFindCompanie = {
        _id: findCompanie._id,
        code: findCompanie.code,
        name: findCompanie.name,
      }
      authData.user.selectedCompany = filteredFindCompanie
      setStorageItem('AUTH_DATA', authData)
      setStorageItem('CURRENT_COMPANY', filteredFindCompanie)
      await store.dispatch('auth/setCurrentCompany', filteredFindCompanie)
      next({
        name: to.name,
        params: { ...to.params, idCompany: storageIdCompany },
      })
    } else {
      next({ name: 'access-denied' })
    }
  }
}
